@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Roboto', sans-serif;

  --primary-text: rgb(34, 55, 88);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  
  --grey: #EDEFF1ff;
  --dark-grey: #b8b8b8;
  --blue: #2049ff;
  --shadow: 0px 5px 20px -10px rgba(59, 59, 59, 1);
}