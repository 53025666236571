.admindashboard__navbar {
    height: 100%;
    width: 12vw;
    position: fixed;
    top: 0;
    left: 0;
    font-family: var(--font-family);
    background-color: #fff;
    overflow-x: hidden;
    box-shadow: var(--shadow);
}
.admindashboard__navbar a {
    padding:1rem 0 1rem 32px;
    text-decoration: none;
    font-size: 15px;
    color: var(--primary-text);
    display: block;
    transition: 0.1s;
}
.admindashboard__navbar a:hover {
    background: var(--grey);
    color: var(--blue);
}
.admindashboard__navbar-logo {
    padding: 1rem 45px 1rem 32px;
    color: var(--primary-text);
    margin: 1rem 0 1rem 0;
}
.admindashboard__navbar-logo h1 {
    font-size: 24px;
    border-bottom: solid 2px var(--blue);
}
.active {
    background-color: var(--grey);
    border-left: solid 3px var(--blue);
}