.admindashboard__card {
    display: flex;
    flex-direction: row;
    width: 15vw;
    border-radius: 1rem;
    justify-content: center;
    background-color: #fff;
    box-shadow: var(--shadow);
    margin: 1rem;
}
.admindashboard__card-body {
    font-family: var(--font-family);
    text-align: center;
    margin: 1rem;
    padding: 2rem;
}
.admindashboard__card-body p {
    font-size: 14px;
    font-weight: 400;
    color: var(--primary-text);
    margin: auto;
}
.admindashboard__card-body h1 {
    font-size: 40px;
    color: var(--primary-text);
    margin: auto;
}