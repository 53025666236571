.admindashboard__newpost-heading {
    font-family: var(--font-family);
    color: var(--primary-text);
    margin-left: 16rem;
    margin-top: 1.5rem;
}
.admindashboard__newpost-heading p {
    font-weight: 200;
    font-size: 13px;
}
.admindashboard__newpost-heading h1 {
    font-size: 30px;
}
