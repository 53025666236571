.admindashboard__dashboard-heading {
    font-family: var(--font-family);
    color: var(--primary-text);
    margin-left: 16rem;
    margin-top: 1.5rem;
}
.admindashboard__dashboard-heading p {
    font-weight: 200;
    font-size: 13px;
}
.admindashboard__dashboard-heading h1 {
    font-size: 30px;
}
.admindashboard__dashboard-cards {
    display: flex;
    flex-direction: row;
}