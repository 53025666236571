.admindashboard__form {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 65vw;
    font-family: var(--font-family);
    background-color: #fff;
    justify-content: center;
    border-radius: 1rem;
    box-shadow: var(--shadow);
    margin: 1rem;
}
.admindashboard__form-title {
    margin: 2rem 2rem 0 2rem;
}
.admindashboard__form-title input {
    width: 100%;
    padding: 0.8rem;
    border: solid 1px var(--dark-grey);
    outline: none;
    margin-bottom: 0.5rem;
}
.admindashboard__form-article {
    margin: 0rem 2rem 1rem 2rem;
}
.ck-editor__editable_inline {
    min-height: 500px;
}
.admindashboard__form-footer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.admindashboard__form-footer button {
    padding: 1rem 1.5rem; 
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    background-color: var(--blue);
    color: #fff;
    border: none;
    cursor: pointer;
    margin: 0rem 2rem 2rem 2rem;
}
.MuiAlert-root {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem;
    transition: 0.5s;
}  